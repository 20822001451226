import * as jsyaml from 'js-yaml';
import { BaseEvalElement } from './base';
import { initializers, loadedEnvironments, mode, postInitializers, pyodideLoaded, scriptsQueue, globalLoader, appConfig, } from '../stores';
import { loadInterpreter } from '../interpreter';
const DEFAULT_RUNTIME = {
    src: 'https://cdn.jsdelivr.net/pyodide/v0.20.0/full/pyodide.js',
    name: 'pyodide-default',
    lang: 'python',
};
let appConfig_ = {
    autoclose_loader: true,
};
appConfig.subscribe((value) => {
    if (value) {
        appConfig_ = value;
    }
    console.log('config set!');
});
let initializers_;
initializers.subscribe((value) => {
    initializers_ = value;
    console.log('initializers set');
});
let postInitializers_;
postInitializers.subscribe((value) => {
    postInitializers_ = value;
    console.log('post initializers set');
});
let scriptsQueue_;
scriptsQueue.subscribe((value) => {
    scriptsQueue_ = value;
    console.log('post initializers set');
});
let mode_;
mode.subscribe((value) => {
    mode_ = value;
    console.log('post initializers set');
});
let pyodideReadyPromise;
let loader;
globalLoader.subscribe(value => {
    loader = value;
});
export class PyodideRuntime extends Object {
    constructor(url) {
        super();
        this.src = url;
    }
    async initialize() {
        loader === null || loader === void 0 ? void 0 : loader.log('Loading runtime...');
        pyodideReadyPromise = loadInterpreter(this.src);
        const pyodide = await pyodideReadyPromise;
        const newEnv = {
            id: 'a',
            promise: pyodideReadyPromise,
            runtime: pyodide,
            state: 'loading',
        };
        pyodideLoaded.set(pyodide);
        // Inject the loader into the runtime namespace
        // eslint-disable-next-line
        pyodide.globals.set('pyscript_loader', loader);
        loader === null || loader === void 0 ? void 0 : loader.log('Runtime created...');
        loadedEnvironments.update((value) => {
            value[newEnv['id']] = newEnv;
        });
        // now we call all initializers before we actually executed all page scripts
        loader === null || loader === void 0 ? void 0 : loader.log('Initializing components...');
        for (const initializer of initializers_) {
            await initializer();
        }
        // now we can actually execute the page scripts if we are in play mode
        loader === null || loader === void 0 ? void 0 : loader.log('Initializing scripts...');
        if (mode_ == 'play') {
            for (const script of scriptsQueue_) {
                script.evaluate();
            }
            scriptsQueue.set([]);
        }
        // now we call all post initializers AFTER we actually executed all page scripts
        loader === null || loader === void 0 ? void 0 : loader.log('Running post initializers...');
        if (appConfig_ && appConfig_.autoclose_loader) {
            loader === null || loader === void 0 ? void 0 : loader.close();
            console.log('------ loader closed ------');
        }
        setTimeout(() => {
            for (const initializer of postInitializers_) {
                initializer();
            }
        }, 3000);
    }
}
export class PyConfig extends BaseEvalElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.code = this.innerHTML;
        this.innerHTML = '';
        const loadedValues = jsyaml.load(this.code);
        if (loadedValues === undefined) {
            this.values = {
                autoclose_loader: true,
            };
        }
        else {
            // eslint-disable-next-line
            // @ts-ignore
            this.values = loadedValues;
        }
        if (this.values.runtimes === undefined) {
            this.values.runtimes = [DEFAULT_RUNTIME];
        }
        appConfig.set(this.values);
        console.log('config set', this.values);
        this.loadRuntimes();
    }
    log(msg) {
        const newLog = document.createElement('p');
        newLog.innerText = msg;
        this.details.appendChild(newLog);
    }
    close() {
        this.remove();
    }
    loadRuntimes() {
        console.log('Initializing runtimes...');
        for (const runtime of this.values.runtimes) {
            const script = document.createElement('script'); // create a script DOM node
            const runtimeSpec = new PyodideRuntime(runtime.src);
            script.src = runtime.src; // set its src to the provided URL
            script.addEventListener('load', () => {
                void runtimeSpec.initialize();
            });
            document.head.appendChild(script);
        }
    }
}
